<template>
  <div>
    <div class="level">
      <div class="level-left" style="padding-left: 0">
        <h1 class="title">Case Study Section</h1>
      </div>
      <div class="level-right">
        <b-field grouped position="is-right">
          <template v-if="data.section.length == 0">
            <b-button class="is-hcc" @click="createSection" title="Add Section"
              >Add Section</b-button
            >
          </template>
          <template v-else-if="data.section.length > 0">
            <div class="show-section">
              <router-link
                :to="{
                  path: `/case-study/show-section/${$route.params.caseStudyId}/${data.section[0].id}`
                }"
                ><b-icon icon="eye" type="is-hcc"></b-icon
              ></router-link>
            </div>
            <div class="edit-section">
              <router-link
                :to="{
                  path: `/case-study/edit-section/${$route.params.caseStudyId}/${data.section[0].id}`
                }"
                >Edit</router-link
              >
            </div>
          </template>
        </b-field>
      </div>
    </div>
    <div>
      <div class="columns is-12 separator-line">
        <div class="column is-2">
          <b>Case Study Name</b>
        </div>
        <div class="column is-10">
          {{ data.section[0] ? data.section[0].name : '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  },
  methods: {
    createSection() {
      this.$router.push({ name: "addCaseStudySection" });
    },

    // For delete popup
    deletePopup(id) {
      this.$buefy.dialog.confirm({
        title: "Delete Section",
        message: `Are you sure want to delete this section?`,
        cancelText: "No, cancel it!",
        confirmText: "Yes, delete it!",
        type: "is-danger",
        onConfirm: () => this.deleteSection(id)
      });
    },

    // For delete section
    deleteSection(id) {
      this.$store
        .dispatch("deleteSection", { id })
        .then(() => {
          this.success("Successfully delete section");
        })
        .catch(() => {
          this.danger("Failed to delete section");
        });
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.show-section {
  padding-right: 10px;
  border-right: 0.5px solid #dddddd;
}
.edit-section {
  padding-left: 10px;
}
</style>
